import { action, autorun, computed, observable, reaction, runInAction, IReactionDisposer } from 'mobx'
import { asyncAction } from 'mobx-utils'
import Application from '@/libs/models'
import Web3 from 'web3'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import { Zero } from '@/constants'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { localdata } from '@/helpers/local-data'
import { isNumber } from 'lodash'
import { appProvider } from '@/app-providers'
import WalletConnectProvider from '@walletconnect/web3-provider'
import {
  WalletAdapter,
  WalletAdapterNetwork,
  SignerWalletAdapter,
  MessageSignerWalletAdapter,
  WalletNotConnectedError,
  WalletSignTransactionError,
  MessageSignerWalletAdapterProps,
  WalletNotFoundError
} from '@solana/wallet-adapter-base'
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
  Wallet
} from '@solana/wallet-adapter-wallets'
import { CLUSTER_SLUGS, ENV as SOL_CHAINID } from '@solana/spl-token-registry'
import { ConfirmOptions, Connection, PublicKey, Transaction } from '@solana/web3.js'
import { Provider } from '@project-serum/anchor'
import { clusterApiUrl } from '@solana/web3.js'

export class WalletStore {
  ethereum: any = (window as any).ethereum

  app = new Application({ mainnet: false })
  @observable web3: Web3 | null = null
  @observable account = ''
  @observable bnbBalance = Zero
  @observable chainId
  @observable loaded = false
  @observable requestedChainId = localdata.lastChainId ? +localdata.lastChainId : 97
  @observable _disposers: IReactionDisposer[] = []
  @observable walletName = ''

  @observable solWalletItems = [getSolletExtensionWallet(), getSolletWallet(), getPhantomWallet(), getSolflareWallet()]
  @observable selectedAdapter: WalletAdapter | SignerWalletAdapter | MessageSignerWalletAdapter | null = null
  @observable chainType: null | string = ''
  @observable selectedWallet: Wallet | null = null
  @observable requestingChain: string | undefined = undefined

  network = WalletAdapterNetwork.Mainnet

  walletConnectProvider = new WalletConnectProvider({
    rpc: {
      56: 'https://bsc-dataseed.binance.org/',
      43114: 'https://api.avax.network/ext/bc/C/rpc',
      137: 'https://rpc-mainnet.maticvigil.com/'
    }
  } as any) as any

  constructor() {
    this._disposers = [
      autorun(() => {
        if (
          this.connected &&
          this.isValidChain &&
          this.isCorrectChain &&
          appProvider.router.currentRoute.name === 'connectWallet'
        ) {
          appProvider.router.push('pools')
        }
      })
    ]
  }

  // destroy() {
  //   this._disposers.forEach(d => d())
  // }

  @action requestedChainIdOnChange(value) {
    this.requestedChainId = value
  }

  @computed get isValidChain() {
    return (
      !this.chainId ||
      +this.chainId === 97 ||
      +this.chainId === 56 ||
      +this.chainId === 80001 ||
      +this.chainId === 137 ||
      +this.chainId === 43114 ||
      +this.chainId === 43113 ||
      +this.chainId === 1 ||
      +this.chainId === 3 ||
      +this.chainId === 101 ||
      +this.chainId === 102 ||
      +this.chainId === 103
    )
  }

  @computed get isCorrectChain() {
    return +this.requestedChainId === +this.chainId
  }

  @asyncAction *start() {
    try {
      const walletConnect = localdata.walletConnect ? localdata.walletConnect : ''
      if (walletConnect) {
        yield this.connectViaWalletConnect()
      } else {
        this.app.start()
        this.web3 = this.app.web3
        if (yield this.app.getAddress()) {
          yield this.connect()
        }
      }
    } catch (error) {
      console.error(error)
    }
    this.loaded = true
  }

  @asyncAction *connect() {
    loadingController.increaseRequest()
    try {
      yield this.disconnectSolana()
      const ok = yield this.app.login()
      this.web3 = this.app.web3
      if (ok) {
        this.web3 = this.app.web3
        this.chainId = yield this.web3!.eth.getChainId()
        this.account = yield this.app.getAddress()
        this.ethereum.removeListener('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.removeListener('chainChanged', this.changeChanged)
        this.ethereum.once('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.once('chainChanged', this.changeChanged)
      }
      this.walletName = 'MetaMask'
      appProvider.setOpenConnectDialog(false)
      return ok
    } catch (error) {
      error.message && snackController.error(error.message)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  // eslint-disable-next-line require-yield
  @asyncAction *connectViaWalletConnect() {
    try {
      loadingController.increaseRequest()
      yield this.disconnectSolana()
      yield this.walletConnectProvider.enable()
      const walletConnect = localdata.walletConnect ? localdata.walletConnect : ''
      const walletConnectParsed = JSON.parse(walletConnect)
      this.account = walletConnectParsed.accounts[0]
      this.chainId = walletConnectParsed.chainId
      this.web3 = new Web3(this.walletConnectProvider)
      this.walletName = 'WalletConnect'
      appProvider.setOpenConnectDialog(false)
      this.walletConnectProvider.on('accountsChanged', (accounts: string[]) => {
        window.location.reload()
      })
      this.walletConnectProvider.on('chainChanged', (chainId: number) => {
        window.location.reload()
      })
    } catch (error) {
      error.message && snackController.error(error.message)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *connectSolana(wallet: Wallet) {
    try {
      loadingController.increaseRequest()
      this.account = ''
      this.web3 = null
      this.ethereum?.removeListener('accountsChanged', this.ethereumConfigChanged)
      this.ethereum?.removeListener('chainChanged', this.ethereumConfigChanged)

      yield this.disconnectSolana()
      const adapter = wallet.adapter()
      if (!adapter) return
      if (!adapter.connected) yield adapter.connect()
      this.selectedWallet = wallet
      this.selectedAdapter = adapter
      this.chainType = 'sol'
      this.chainId = CLUSTER_SLUGS[this.network]
      this.account = adapter.publicKey?.toString() || ''
      ;(adapter as any).on('disconnect', () => this.disconnectSolana())
      ;(adapter as any).on('error', err => snackController.error(`${err.name} ${err.message}`))
      appProvider.setOpenConnectDialog(false)
      return true
    } catch (error) {
      if (error instanceof WalletNotFoundError) {
        snackController.error(`Plugin ${wallet.name} is not installed!`)
      } else snackController.error(error as any)
      console.error(error)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *disconnectSolana() {
    const adapter = this.selectedAdapter
    if (adapter) {
      this.account = ''
      this.chainType = null
      this.selectedAdapter = null
      this.selectedWallet = null
      adapter.removeAllListeners('disconnect')
      adapter.removeAllListeners('error')
      adapter.removeAllListeners()
      yield adapter.disconnect().catch()
    }
  }

  @asyncAction *logout() {
    try {
      loadingController.increaseRequest()
      if (this.walletName === 'WalletConnect') {
        localdata.removeWalletConnect()
        yield this.walletConnectProvider.disconnect()
        appProvider.setOpenConnectDialog(false)
      } else if (this.chainType === 'sol') {
        yield this.disconnectSolana()
      }
      window.location.reload()
    } catch (error) {
      error.message && snackController.error(error.message)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  ethereumConfigChanged = () => {
    window.location.reload()
  }
  changeChanged = chainId => {
    const chainIdNumber = isNumber(+chainId) ? +chainId : null
    if (chainIdNumber) {
      if (
        (!localdata.lastChainId || +localdata.lastChainId !== chainIdNumber) &&
        (chainIdNumber === 97 || chainIdNumber === 56)
      ) {
        localdata.lastChainId = chainIdNumber.toString() as any
      }
    }
    window.location.reload()
  }

  async switchNetwork(chain, chainId: number) {
    if (chain === 'sol') {
      let network = WalletAdapterNetwork.Mainnet
      if (chainId === SOL_CHAINID.Testnet) network = WalletAdapterNetwork.Testnet
      if (chainId === SOL_CHAINID.Devnet) network = WalletAdapterNetwork.Devnet
      if (network != this.network) {
        await this.disconnectSolana()
        this.network = network
        this.solWalletItems = [
          getSolletExtensionWallet({ network }),
          getSolletWallet({ network }),
          getPhantomWallet(),
          getSolflareWallet()
        ]
      }
      this.requestingChain = chain
      appProvider.setOpenConnectDialog(true)
    } else {
      if (this.connected) {
        try {
          await this.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(chainId) }]
          })
        } catch (error) {
          if (error.message.includes('wallet_addEthereumChain')) {
            if (chainId === 56) {
              this.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: Web3.utils.toHex(chainId),
                    chainName: 'Binance Smart Chain Mainnet',
                    nativeCurrency: {
                      name: 'Binance Chain Native Token',
                      symbol: 'BNB',
                      decimals: 18
                    },
                    rpcUrls: [
                      'https://bsc-dataseed1.binance.org',
                      'https://bsc-dataseed2.binance.org',
                      'https://bsc-dataseed3.binance.org',
                      'https://bsc-dataseed4.binance.org',
                      'https://bsc-dataseed1.defibit.io',
                      'https://bsc-dataseed2.defibit.io',
                      'https://bsc-dataseed3.defibit.io',
                      'https://bsc-dataseed4.defibit.io',
                      'https://bsc-dataseed1.ninicoin.io',
                      'https://bsc-dataseed2.ninicoin.io',
                      'https://bsc-dataseed3.ninicoin.io',
                      'https://bsc-dataseed4.ninicoin.io',
                      'wss://bsc-ws-node.nariox.org'
                    ],
                    blockExplorerUrls: ['https://bscscan.com']
                  }
                ]
              })
            } else if (chainId === 97) {
              this.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: Web3.utils.toHex(chainId),
                    chainName: 'Binance Smart Chain Testnet',
                    nativeCurrency: {
                      name: 'Binance Chain Native Token',
                      symbol: 'tBNB',
                      decimals: 18
                    },
                    rpcUrls: [
                      'https://data-seed-prebsc-1-s1.binance.org:8545',
                      'https://data-seed-prebsc-2-s1.binance.org:8545',
                      'https://data-seed-prebsc-1-s2.binance.org:8545',
                      'https://data-seed-prebsc-2-s2.binance.org:8545',
                      'https://data-seed-prebsc-1-s3.binance.org:8545',
                      'https://data-seed-prebsc-2-s3.binance.org:8545'
                    ],
                    blockExplorerUrls: ['https://testnet.bscscan.com']
                  }
                ]
              })
            }
          }
        }
      }
    }
  }

  //#region computed
  @computed get connected() {
    return !!this.account
  }

  @computed get solanaConnected() {
    return this.chainType === 'sol' && !!this.account
  }

  @computed get shortAccount() {
    if (!this.account) return ''
    return this.account.substr(0, 5) + '...' + this.account.substr(this.account.length - 3)
  }

  @computed get isChainIdValid() {
    //TODO: change to mainnet
    return this.chainId && this.chainId + '' === Number(97).toString()
  }

  @computed get connectedSolProvider() {
    const [wallet, adapter] = [this.selectedWallet, this.selectedAdapter]
    if (wallet && adapter) {
      let anchorWallet: MyAnchorWallet | undefined = undefined
      const opts: ConfirmOptions = {
        preflightCommitment: 'recent',
        commitment: 'recent'
      }
      const connection = new Connection(
        this.network === WalletAdapterNetwork.Mainnet
          ? 'https://solana-api.projectserum.com'
          : clusterApiUrl(this.network),
        opts.preflightCommitment
      )

      // //TODO: Dynamic chain RPC
      // const connection = new Connection('https://api.devnet.solana.com', opts.preflightCommitment)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      anchorWallet = new MyAnchorWallet(adapter as SignerWalletAdapter)
      return new Provider(connection, anchorWallet, opts)
    }
    return null
  }
  @action copyAddress() {
    navigator.clipboard.writeText(this.account)
  }
  //#endregion
}

export const walletStore = new WalletStore()

export class MyAnchorWallet {
  constructor(readonly adapter: SignerWalletAdapter | undefined) {
    //
  }

  async signTransaction(tx: Transaction): Promise<Transaction> {
    try {
      return (await this.adapter?.signTransaction(tx)) as any
    } catch (error) {
      if (error instanceof WalletNotConnectedError) {
        snackController.error('Wallet disconnected, reloading...')
        window.location.reload()
      } else if (error instanceof WalletSignTransactionError) {
        snackController.error('You might change wallet, please connect again!')
        // walletStore.disconnectSolana()
      }
      throw error
    }
  }
  async signAllTransactions(txs: Transaction[]): Promise<Transaction[]> {
    try {
      return (await this.adapter?.signAllTransactions(txs)) as any
    } catch (error) {
      if (error instanceof WalletNotConnectedError) {
        snackController.error('Wallet disconnected, reloading...')
        window.location.reload()
      } else if (error instanceof WalletSignTransactionError) {
        snackController.error('You might change wallet, please connect again!')
        // walletStore.disconnectSolana()
      }
      throw error
    }
  }
  get publicKey(): PublicKey {
    return this.adapter?.publicKey as any
  }
}
