import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import { pluginsRegister } from './plugins/plugins-register'
import { vueFilterRegister } from './plugins/vue-filter-register'
import { componentRegister } from './plugins/component-register'
import VueCodemirror from 'vue-codemirror'

Vue.config.productionTip = false
Vue.use(VueCodemirror)
pluginsRegister()
componentRegister()
vueFilterRegister()
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
