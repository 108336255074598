import Web3 from "web3";
import Account from './Account';
import { computed, observable, runInAction, action } from 'mobx'
const BSC_URL_TESTNET  = "https://data-seed-prebsc-1-s1.binance.org:8545/"
const BSC_URL_MAINNET="https://bsc-dataseed.binance.org/"

class Application {

	constructor({ test = false, mainnet = true, network = 'BSC' }) {
		this.test = test;
		this.mainnet = mainnet;
		if ((network != 'BSC')) {
			throw new Error("Network has to be BSC");
		}
		this.network = network;
		this.isMetamask = false
	}

	start = () => {
		if (this.network == 'BSC') {
			this.web3 = new Web3(
				new Web3.providers.HttpProvider(
					(this.mainnet == true) ? BSC_URL_MAINNET : BSC_URL_TESTNET
				)
			);
		} 
		if (typeof window.ethereum !== "undefined") {
			window.web3 = this.web3;
			this.isMetamask = true

		} else {
			if (!this.test) {
				this.isMetamask = false
				throw new Error("Please Use an Ethereum Enabled Browser like Metamask or Coinbase Wallet");
			}
		}
	}
	login = async () => {
		try {
			if (typeof window === "undefined") { 
				return false; 
			}
			if (window.ethereum) {
				window.web3 = new Web3(window.ethereum);
				this.web3 = window.web3;
				await window.ethereum.request({ method: 'eth_requestAccounts' })
				return true;
			}
			return false;
		} catch (err) {
			throw err;
		}
	};
	/* Get User Address */
	getAddress = async () => {
		const accounts = await window.ethereum.request({ method: 'eth_accounts' });
		if (accounts.length) return Web3.utils.toChecksumAddress(accounts[0]);
		return null
	};

	/* Get User Balance in Ethereum */
}

export default Application;
