import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      dark: {
        primary: {
          base: '#FFC107',
          lighten1: '#181718',
          lighten2: '#2c2230',
          lighten3: '#181718',
          lighten4: '#2d2d2d',
          lighten5: '#F8E4A7'
        },
        success: {
          base: '#5FCD5B',
          lighten1: '#dff5de',
          lighten2: '#46be8a'
        },
        lightGray: '#969696',
        grey1: {
          base: '#B9B9B1',
          lighten1: '#F2F2F0'
        },
        red1: '#F6616A',
        error: {
          base: '#F44336',
          lighten1: '#ff5c59'
        }
      }
    }
  }
})
