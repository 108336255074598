/* eslint-disable prefer-const */
import XLSX from 'xlsx'
import { isEmpty } from 'lodash'
export default {
  csvJSON(csv, headers = ['address', 'amount'], defaultValue = '0') {
    const lines = csv.split('\n')
    const result: any = []
    for (let i = 1; i < lines.length; i++) {
      if (isEmpty(lines[i])) continue
      const obj = {}
      const currentline = lines[i].trim().split(',')
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j] || defaultValue
      }
      result.push(obj)
    }
    return result
  },
  xlsxToJson(xlsx, headers = { A: 'address', B: 'amount' }) {
    const workbook = XLSX.read(xlsx, { type: 'binary' })
    const sheet_name_list = workbook.SheetNames
    let res: any = []
    sheet_name_list.forEach(function(y) {
      const worksheet = workbook.Sheets[y]
      const data: any = []
      for (let z in worksheet) {
        if (z[0] === '!') continue
        //parse out the column, row, and value
        const col = z.substring(0, 1)
        const row = parseInt(z.substring(1))
        const value = worksheet[z].v
        if (row == 1) continue
        if (!data[row]) data[row] = {}
        data[row][headers[col]] = value || ''
      }
      //drop those first two rows which are empty
      data.shift()
      data.shift()
      res = [...res, ...data]
    })
    return res
  }
}
