import { ChainIdType } from '@/blockchainHandlers'

class LocalData {
  get lastChainId(): ChainIdType | null {
    return localStorage.getItem('lastChainId') as ChainIdType
  }
  set lastChainId(value: ChainIdType | null) {
    if (value) localStorage.setItem('lastChainId', value)
    else localStorage.removeItem('lastChainId')
  }

  get walletConnect() {
    return localStorage.getItem('walletconnect')
  }

  set web3Provider(value: any) {
    localStorage.setItem('web3Provider', value)
  }

  get web3Provider() {
    return localStorage.getItem('web3Provider')
  }

  removeWalletConnect() {
    localStorage.removeItem('walletconnect')
  }
}

export const localdata = new LocalData()
