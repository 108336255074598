import { walletStore } from '@/stores/wallet-store'
import { when } from 'mobx'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { appProvider } from '@/app-providers'
import { snackController } from '@/components/snack-bar/snack-bar-controller'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/home' },
  {
    path: '/home',
    name: 'home',
    component: () => import('../modules/home/pages/home.vue'),
    meta: {
      title: 'home'
    }
  },
  {
    path: '/claimer/connect-wallet',
    name: 'connectWallet',
    component: () => import('../modules/claimer/pages/claimer-connect-wallet.vue'),
    meta: {
      title: 'claimer'
    }
  },
  {
    path: '/claimer/pools/user',
    name: 'claimerDetailUser',
    component: () => import('../modules/claimer/pages/claimer-detail-user.vue'),
    meta: {
      title: 'claimer'
    }
  },
  {
    path: '/claimer/pools',
    name: 'claimerPools',
    component: () => import('../modules/claimer/pages/claimer-pools.vue'),
    meta: {
      title: 'claimer'
    }
  },
  {
    path: '/claimer/pools/owner',
    name: 'claimerDetailOwner',
    component: () => import('../modules/claimer/pages/claimer-detail-owner.vue'),
    meta: {
      title: 'claimer'
    }
  },
  {
    path: '/claimer/pools/owner/:id/add-winner',
    name: 'addWinner',
    component: () => import('../modules/claimer/pages/add-winner.vue'),
    meta: {
      title: 'claimer'
    }
  },
  {
    path: '/sender',
    name: 'sender',
    component: () => import('@/modules/sender/pages/sender.vue')
  },
  {
    path: '/sender/connect-wallet',
    name: 'sender/connect-wallet',
    component: () => import('@/modules/sender/pages/sender-connect-wallet.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  await when(() => {
    return walletStore.loaded
  })
  const connected = walletStore.connected
  if (to.name === 'sender' || to.name === 'claimerPools') {
    if (to.name === 'claimerPools' && walletStore.solanaConnected) {
    // if (walletStore.solanaConnected) {
      snackController.error('Solana chain is comming soon. Please connect with Metamask or Wallet Connect')
      next('/home')
      appProvider.setOpenConnectDialog(true)
    }
    if (!connected) {
      next('/home')
      appProvider.setOpenConnectDialog(true)
    } else if (!walletStore.isValidChain) {
      next('/home')
      snackController.error(
        'The system is not supported this chain yet. Please select Avalanche, Binance Smart Chain or Polygon network'
      )
    } else next()
  } else next()
  // if (to.name === 'connectWallet') {
  //   if (!connected || !walletStore.isValidChain) next()
  //   else next('/claimer/pools')
  // } else if (to.name === 'sender/connect-wallet') {
  //   if (!connected || !walletStore.isCorrectChain) next()
  //   else next('sender')
  // } else if (to.name === 'sender') {
  //   if (!connected || !walletStore.isCorrectChain) next('/sender/connect-wallet')
  //   else next()
  // } else if (to.name === 'home' || to.name === 'claimerDetailUser') {
  //   next()
  // } else if (!connected || !walletStore.isValidChain || !walletStore.isCorrectChain) next('/claimer/connect-wallet')
  // else next()
})

export default router
