import VueRouter from 'vue-router'
import { walletStore } from './stores/wallet-store'
import { action, computed, observable, reaction } from 'mobx'
import { localData } from './stores/local-data'

export class AppProvider {
  router!: VueRouter
  wallet = walletStore
  // @observable lightmode = localData.lightmode
  @observable lightmode = false
  @observable openConnectDialog = false

  constructor() {
    reaction(
      () => this.lightmode,
      mode => (localData.lightmode = mode),
      { fireImmediately: true }
    )
  }

  @action setOpenConnectDialog(open: boolean) {
    this.openConnectDialog = open
  }

  @computed get themeType() {
    return this.lightmode ? 'light' : 'dark'
  }
}

export const appProvider = new AppProvider()
